// import { mapAssetsDocs } from "api/docs";
// import { getAllUniqueFiles } from "api/fileManager";
import { getAllTags, getTagsByEquipmentsId, mapEquipmentTag } from "api/tags";

const mappingModalApiConfig = {
  mapAssetsDocs: () => null,
  getAllUniqueFiles: () => null,
  getAllTags,
  getTagsByEquipmentsId,
  mapEquipmentTag
};

export default mappingModalApiConfig;