import { ReactComponent as ProcessHubLogo } from "assets/logo.svg";
import { useState } from "react";
import { FiArrowLeft, FiMenu } from "react-icons/fi";
import { Link } from "react-router-dom";
import { SideMenu, DateTime, UserMenu, Info } from "processhub-components-lib";
import useApp from "hooks/useApp";
import classNames from "utilities/ClassNames";
import { ReactComponent as LeapLogo } from 'assets/leap_logo.svg';
import { ReactComponent as HealthsensorIcon } from "assets/healthsensorIcon.svg";
import { useNavigate } from "react-router-dom";
import TakamulLogo from 'assets/TT_logo_white.png';
import setBodyColor from "test";
import LaunchUri from "utilities/LaunchUri";
import { useLocation } from "react-router-dom";
import allHooks from "hooks";
import { profileConfig } from "./config/userMenuConfig";
import { navLinks } from "./config/navLinksConfig";
import { useAuth } from "react-oidc-context";

export default function Header() {
  const { userRole } = useApp();
  const isScaled = false;
  const { pathname } = useLocation();
  const [showSideMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const auth = useAuth();
  const logout = async () => auth.signoutRedirect();
  const linkTarget = "_self";

  const menus = [
    {
      key: "dashboard",
      name: "Dashboard",
      path: "/dashboard",
      islink: true,
      target: linkTarget,
      adminView: false,
      isRight: false,
    },
    {
      key: "unity",
      name: "3D Exhibition",
      path: "/3d-exhibition",
      islink: true,
      target: linkTarget,
      adminView: false,
      isRight: false,
    },
  ];

  if (userRole === 'admin' && window.vuplex) {
    menus.push({
      key: "empty",
      name: "3D in App",
      path: "/unity",
      islink: true,
      target: linkTarget,
      adminView: false,
      isRight: true,
    });
  }

  if (pathname === "/unity") {
    setBodyColor({ color: "transparent" });
  } else {
    setBodyColor({ color: "rgb(57,57,57,1)" });
  }

  const OpenInApp = () => {
    const link = "leap24-app://123456";
    LaunchUri(
      link,
      () => { }, // SUCCESS - the protocol is registered and the user was asked to open
      () => {
        // FAILURE - the protocol isn't registered
        const link = document.createElement("a");
        link.href = "/leap24-app.exe";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      () => console.error("Something went wrong while open in App")
    );
  };

  const ShowAppButton = () => {
    if (navigator.userAgent.indexOf("Unity") > -1) return false;

    if (navigator.platform.indexOf("Win") > -1) return true;

    return false;
  };

  return (
    <div className="w-full bg-dark relative z-40 ">
      <div
        className={classNames(
          "h-16 lg:h-9 xl:h-12 2xl:h-14",
          "mx-auto w-full flex items-center px-3"
        )}
      >
        <div className="relative flex items-center gap-5 w-full">
          <div
            onClick={() => setShowMenu(!showSideMenu)}
            className="cursor-pointer duration-300 z-50"
          >
            {!showSideMenu && <FiMenu size={isScaled ? 40 : 30} />}
            {showSideMenu && <FiArrowLeft size={isScaled ? 40 : 30} />}
          </div>
          <Link to="/" className="z-50">
            <ProcessHubLogo className={classNames("lg:h-6 2xl:h-8")} />
          </Link>
          <div className="w-full">
            <div className="flex ml-4 gap-4 w-full">
              {menus.map((page) => {
                return (
                  <div key={page.name} className={classNames(page.isRight ? (" mr-4 ml-auto") : "")}>
                    <Link target={page.target} to={page.path}>
                      <>
                        <div className={classNames("lg:h-[2.15rem] 2xl:h-[2.65rem] w-full", "rounded-lg bg-gradient-to-r from-green to-blue  items-center justify-center")}>
                          <button className={classNames("h-[2.40rem] w-40 text-xl lg:h-[1.95rem] 2xl:h-[2.40rem] lg:w-32 2xl:w-40 lg:text-sm 2xl:text-lg", "text-center bg-gradient-to-r from-gray to-black rounded-lg hover:opacity-70 text-white mt-0.5 mb-0.5 ml-0.5 mr-0.5 ")}>
                            {page.name}
                          </button>
                        </div>
                      </>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="flex-1" />
        <div className="flex items-center">
          <DateTime hooks={allHooks} />
          <div className="hover:text-green cursor-pointer w-min mr-6" onClick={() => navigate('/health')}>
            <HealthsensorIcon className={classNames(isScaled ? "h-[19px] w-[19px]" : "h-[23px] w-[23px] border-white rounded-full hover:text-green")} />
          </div>
          <div className="w-max">
            <Info hooks={allHooks} />
          </div>
          <UserMenu hooks={allHooks} profileConfig={profileConfig} logout={logout} />
          <SideMenu
            closeMenu={() => setShowMenu(false)}
            showSideMenu={showSideMenu}
            navLinks={navLinks}
            hooks={allHooks}
          />
        </div>
      </div>
      <div className={classNames(isScaled ? "h-[80px]" : "h-[64px] lg:h-[44px] xl:h-[54px] 2xl:h-[64px]", "absolute top-0 lg:left-[40%]  xl:left-[41.5%] 2xl:left-[42.5%] flex")}>
        <Link to="/" className="flex w-min items-center mx-auto">
          <LeapLogo className="h-8 text-white w-[240px] lg:w-[200px] xl:w-[220px] 2xl:w-[240px]" />
          <span className="block text-xl lg:text-lg 2xl:text-xl mt-3 lg:mt-1 2xl:mt-3 ml-1 font-bold">
            by
          </span>
          <img src={TakamulLogo} alt="Takamul Logo" className="w-28 lg:w-20 2xl:w-24 ml-1.5 mb-0.5" />
        </Link>
      </div>
    </div>
  );
}
