import { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import useApp from "hooks/useApp";
import setBodyColor from "test";
import LaunchUri from "utilities/LaunchUri";
import { useLocation } from "react-router-dom";
import Header from "./Header";

export default function UnityLayout() {
  const { userRole } = useApp();
  const isScaled = false;
  const { pathname } = useLocation();
  const [showSideMenu, setShowMenu] = useState(false);
  const linkTarget = "_self";

  const menus = [
    {
      key: "dashboard",
      name: "Dashboard",
      path: "/dashboard",
      islink: true,
      target: linkTarget,
      adminView: false,
      isRight: false,
    },

    {
      key: "unity",
      name: "3D Exhibition",
      path: "/3d-exhibition",
      islink: true,
      target: linkTarget,
      adminView: false,
      isRight: false,
    },
  ];

  if (userRole === 'admin') {
    menus.push({
      key: "empty",
      name: "3D in App",
      path: "/unity",
      islink: true,
      target: linkTarget,
      adminView: false,
      isRight: true,
    });
  }

  if (pathname === "/unity") {
    setBodyColor({ color: "transparent" });
  } else {
    setBodyColor({ color: "rgb(57,57,57,1)" });
  }

  const OpenInApp = () => {
    const link = "leap24-app://123456";
    LaunchUri(
      link,
      () => { }, // SUCCESS - the protocol is registered and the user was asked to open
      () => {
        // FAILURE - the protocol isn't registered
        const link = document.createElement("a");
        link.href = "/leap24-app.exe";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      () => console.error("Something went wrong while open in App")
    );
  };

  const ShowAppButton = () => {
    if (navigator.userAgent.indexOf("Unity") > -1) return false;

    if (navigator.platform.indexOf("Win") > -1) return true;

    return false;
  };
  return (
    <div className="h-full flex flex-col">

      <div className="relative overflow-hidden w-full h-full grow mx-auto">
        <Header />
        <Outlet />
      </div>
    </div>
  );
}
