import loginBackground from "../../assets/loginBackground.jpg";
import logo from "../../assets/TT_logo_black.png";
import { Link } from "react-router-dom";
import { ReactComponent as LeapLogo } from 'assets/leap_logo.svg';
import classNames from "utilities/ClassNames";

export default function TempDashboard() {
  const isScaled = false;
  let tjson: any = 'test';
  function addMessageListener() {
    window.vuplex.addEventListener("message", function (event: { data: any }) {
      tjson = event.data;
    });
  }
  if (window.vuplex) {
    addMessageListener();

  } else {
    window.addEventListener('someTestMethod', addMessageListener);
  }
  return (
    <div>
      <div className="absolute left-0 right-0 top-5 -bottom-10 outline">
        <img src={loginBackground} className="w-full h-full"></img>
        <div className="lg:left-[36%] xl:left-[36%] 2xl:left-[39%] top-[39%] absolute h-min w-max">
          <LeapLogo className={classNames(isScaled ? "h-40" : "h-32", " text-gray w-full")} />
          <span className={classNames(isScaled ? "text-4xl mt-2" : "text-2xl mt-1", "text-black opacity-75 font-bold block")}>By Takamul</span>
        </div>
      </div>
      <div className={classNames(isScaled ? "w-60 bottom-10 left-6" : "w-40 bottom-0 left-4", "absolute h-20")}>
        <img src={logo}></img>
      </div>
      <div className={classNames(isScaled ? "text-2xl mb-4" : "", "absolute bottom-0 left-[45%] flex mb-2")}>
        <Link to={"https://processhub.ai/"} style={{ color: 'darkblue', textDecoration: 'underline' }}>www.processhub.ai</Link>
      </div>
    </div>
  );
}
