import {
  getAlarms,
} from "api/alarm";
import { useEffect, useMemo, useState } from "react";
import { Loader, TableManager, DataTable, BorderGradient, Modal } from "processhub-components-lib";
import allHooks from "hooks";
import dayjs from "dayjs";
import Debounce from "utilities/Debounce";
import { getAllTagsWithParents } from "api/tags";
import { Item, ActionName } from "types/common";
import { updateAlarm } from "api/alarm";
import modalConfig from "config/modal/modalConfig";

export default function Alarms() {
  const { useApp } = allHooks;
  const { userRole } = useApp();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<any>(null);
  const [data, setData] = useState<any>([]);
  const [processedData, setProcessedData] = useState<any>([]);
  const [tagsData, setTagsData] = useState<any>(null);
  const [selectedTag, setSelectedTag] = useState<any>({
    action: "",
    value: null,
  });

  const alarm = async (id: any, isAlertTag: any) => {
    const response: any = await updateAlarm(id, isAlertTag ? 2 : 1);
    if (response.status === 200) {
      refreshData();
    }
  };

  const getData = async () => {
    setLoading(true);
    const response: any = await getAlarms();
    if (response.status === 200) {
      setData(response.data);
    } else {
      setError(true);
    }
    setLoading(false);
  };

  const getTagsData = async () => {
    const response: any = await getAllTagsWithParents();
    if (response.status === 200) {
      setTagsData(response.data);
    } else {
      setError(true);
    }
  };

  const refreshData = async () => {
    const response: any = await getAlarms();
    if (response.status === 200) {
      setData(response.data);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getTagsData();
  }, []);

  // Table
  const [keyword, setKeyword] = useState("");
  const [debouncedKeyword, setDebouncedKeyword] = useState("");

  Debounce(() => setDebouncedKeyword(keyword), [keyword], 500);

  const rows = useMemo(() => {
    let items = processedData;
    const keyword = debouncedKeyword.toString().toLowerCase();
    if (keyword !== "") {
      items = data?.filter((item: Item) => {
        const hasTag = item.tagNameIdentifier ?
          (item.tagNameIdentifier.toLowerCase().includes(keyword) ||
            item.tagNameIdentifier.toLowerCase() === keyword) : false;
        const hasName = item?.tagName?.toLowerCase().includes(keyword) ||
          item?.tagName?.toLowerCase() === keyword;
        return hasTag || hasName;
      });
      return items;
    }
    return items;
  }, [processedData, debouncedKeyword]);

  const handleClick = (itemValue: any, itemAction: ActionName) => {
    const selectedItem = tagsData.find((singleTag: Item) => singleTag.id === itemValue.tagId);
    if (itemAction !== 'chart') {
      setSelectedTag({
        action: itemAction,
        value: itemValue,
      });
    }
    else {
      setSelectedTag({
        action: itemAction,
        value: selectedItem
      });
    }
  };

  useEffect(() => {
    if (data.length > 0) {
      const newData = data.map((singleItem: any) => {
        singleItem.createdOn = `${dayjs(singleItem.createdOn).format("MM/DD/YYYY")}, ${dayjs(singleItem.createdOn).format("HH:mm:ss A")}`;
        singleItem.statusId = singleItem.statusId === 0 ? 'New' : 'Acknowledged';
        return singleItem;
      });
      setProcessedData(newData);
    }
  }, [data]);

  const btnAction = () => {
    setSelectedTag({
      action: 'acknowledgeAll',
      value: null,
    });
  };

  const secondaryBtnAction = () => {
    setSelectedTag({
      action: 'deleteAll',
      value: null,
    });
  };

  return (
    <>
      {loading && (
        <div className="fixed inset-0 grid place-content-center bg-black/70">
          <Loader />
        </div>
      )}
      {error && (
        <div className="w-full h-[80vh] grid place-content-center">
          <p>Error retrieving Alarms, try refreshing the page</p>
        </div>
      )}
      {!loading && !error && processedData && (
        <div className="pl-3 pt-3 pb-3 overflow-hidden overflow-y-scroll">
          <TableManager type="alarms" setKeyword={setKeyword} btnAction={btnAction} secondaryBtnAction={secondaryBtnAction} searchRightSide={false} userRole={userRole} />
          <div className="mt-3 h-[84vh]">
            <BorderGradient className="overflow-hidden h-full">
              <DataTable type="alarms" hasPins={false} hasActions={true} rows={rows} onClickHandler={handleClick} onActionClickHandler={handleClick} userRole={userRole} />
            </BorderGradient>
          </div>
          <Modal page='alarms' selectedTag={selectedTag} setSelectedTag={setSelectedTag} isAlarm={alarm} isLoading={loading} refresh={refreshData} modalConfig={modalConfig} hooks={allHooks} />
        </div>
      )}
    </>
  );
}
